import React, { PureComponent } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';

export class Loader extends PureComponent {
  render() {
    const {
      position = 'fixed',
      zIndex = 1000,
      background = '#fff',
    } = this.props;
    return (
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justify="center"
        style={{ minHeight: '100vh', zIndex, background, position, top: 0 }}
      >
        <Grid item xs={12}>
          <CircularProgress color="primary" size={55} />
        </Grid>
      </Grid>
    );
  }
}
