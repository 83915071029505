export const SET_USER_DETAILS = 'SET_USER_DETAILS';
export const UPDATE_ORGANIZATION_INFO = 'UPDATE_ORGANIZATION_INFO';
export const SET_JOB_LISTING = 'SET_JOB_LISTING';
export const SET_APP_INITIALIZED = 'SET_APP_INITIALIZED';
export const RESET_SELECTED_JOB = 'RESET_SELECTED_JOB';
export const SET_SELECTED_JOB = 'SET_SELECTED_JOB';
export const UPDATE_SELECTED_JOB = 'UPDATE_SELECTED_JOB';
export const SET_CANDIDATE_LISTING = 'SET_CANDIDATE_LISTING';
export const SET_SELECTED_CANDIDATE = 'SET_SELECTED_CANDIDATE';
export const SET_FLAG = 'SET_FLAG';
export const RESET_FLAGS = 'RESET_FLAGS';
export const SET_JOB_STATUS_COUNTS = 'SET_JOB_STATUS_COUNTS';
export const TOGGLE_CANDIDATE_AS_SEEN = 'TOGGLE_CANDIDATE_AS_SEEN';
export const TOGGLE_CANDIDATE_AS_FAVOURITE = 'TOGGLE_CANDIDATE_AS_FAVOURITE';
export const SET_TALENT_STATUS_COUNT = 'SET_TALENT_STATUS_COUNT';
export const ADD_COLLEGE = 'ADD_COLLEGE';
export const REMOVE_COLLEGE = 'REMOVE_COLLEGE';
export const ADD_DOMAIN = 'ADD_DOMAIN';
export const REMOVE_DOMAIN = 'REMOVE_DOMAIN';
export const ADD_PERKS = 'ADD_PERKS';
export const REMOVE_PERKS = 'REMOVE_PERKS';
export const COPY_PERKS = 'COPY_PERKS';
export const SET_USER_INTEGRATIONS = 'SET_USER_INTEGRATIONS';
export const SET_NOTIFICATIONS = 'SET_NOTIFICATIONS';
export const SET_MENU_VISIBILITY = 'SET_MENU_VISIBILITY';

// Talent

export const SET_PIPELINE = 'SET_PIPELINE';
export const SET_SELECTED_PIPELINE = 'SET_SELECTED_PIPELINE';
export const SET_SELECTED_TALENT_TAB = 'SET_SELECTED_TALENT_TAB';
export const SET_TALENT_LIST = 'SET_TALENT_LIST';
export const RESET_TALENT_LIST = 'RESET_TALENT_LIST';
export const SET_SELECTED_TALENT = 'SET_SELECTED_TALENT';
export const SET_TALENT_LIST_LOADED = 'SET_TALENT_LIST_LOADED';
export const SET_TALENT_PAGINATION = 'SET_TALENT_PAGINATION';
export const SET_FILTER_OPTIONS = 'SET_FILTER_OPTIONS';
export const SET_FILTER_ACCORDIAN = 'SET_FILTER_ACCORDIAN';
export const SET_OVERVIEW_LEFT_MENU_SELECTED =
  'SET_OVERVIEW_LEFT_MENU_SELECTED';
