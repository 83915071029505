const ENVIRONMENTS = {
  'https://test.senseloaf.com': 'https://dev.senseloaf.com/',
  'https://app.senseloaf.com': 'https://service.senseloaf.com/',
  'https://sandbox.senseloaf.ai': 'https://service.senseloaf.com/',
  'http://localhost:3000': '/',
};

const getServiceUrl = () => {
  const HOST = window.location.origin;

  return ENVIRONMENTS[HOST] || '/';
};

export const SERVICE_URL = getServiceUrl();
