import { createMuiTheme } from '@material-ui/core/styles';

const senseloafPrimaryColor = {
  50: '#E4F3FF',
  100: '#BFE0FF',
  200: '#96CDFF',
  300: '#6DB9FF',
  400: '#50A9FF',
  500: '#3D9AFF',
  600: '#3D8CF8',
  700: '#4B8C6F',
  800: '#3867D1',
  900: '#3348B2',
  A100: '#b9f6ca',
  A200: '#69f0ae',
  A400: '#00e676',
  A700: '#00c853',
  main: '#4B8C6F',
  contrastText: '#fff',
};

const senseloafSecondaryColor = {
  50: '#E0F7FA',
  100: '#B2EBF2',
  200: '#80DEEA',
  300: '#4DD0E1',
  400: '#26C6DA',
  500: '#00BCD4',
  600: '#00ACC1',
  700: '#0097A7',
  800: '#00838F',
  900: '#006064',
  A100: '#b9f6ca',
  A200: '#69f0ae',
  A400: '#00e676',
  A700: '#00c853',
  main: '#4DD0E1',
  light: '#4DD0E1',
  contrastText: '#fff',
};

const theme = createMuiTheme({
  palette: {
    primary: senseloafPrimaryColor,
    secondary: senseloafSecondaryColor,
    senseloaf: {
      primary: '#4b8c6f',
    },
    matchingDetailsPage: {
      light: {
        bg: '#F4F4F4',
        textPrimary: '#000',
        textSecondary: '#FFFFFF',
        border: '#EFEFEF',
        textGrey: '#D9D9D9',
      },
      dark: {
        bg: '#191826',
        textPrimary: '#202133',
        textSecondary: '#202133',
        border: '#282A4A',
        textGrey: '#282A4A',
      },
    },
  },
  typography: {
    h1: {
      fontWeight: 500,
      fontSize: '96px',
      letterSpacing: '-1.5px',
    },
    h2: {
      fontWeight: 300,
      fontSize: '60px',
      letterSpacing: '-0.5px',
    },
    h3: {
      fontWeight: 300,
      fontSize: '48px',
      letterSpacing: '0px',
    },
    h4: {
      fontWeight: 300,
      fontSize: '34px',
      letterSpacing: '0.25px',
    },
    h5: {
      fontWeight: 300,
      fontSize: '24px',
      letterSpacing: '0px',
    },
    h6: {
      fontWeight: 300,
      fontSize: '20px',
      letterSpacing: '0.25px',
    },
    subtitle1: {
      fontWeight: 300,
      fontSize: '16px',
      letterSpacing: '0.15px',
    },
    subtitle2: {
      fontWeight: 300,
      fontSize: '14px',
      letterSpacing: '0.1px',
    },
    body1: {
      fontWeight: 300,
      fontSize: '16px',
      letterSpacing: '0.5px',
    },
    body2: {
      fontWeight: 300,
      fontSize: '14px',
      letterSpacing: '0.25px',
    },
    button: {
      fontWeight: 500,
      fontSize: '14px',
      letterSpacing: '1.25px',
      textTransform: 'uppercase',
      fontFamily: 'Roboto',
    },
    caption: {
      fontWeight: 500,
      fontSize: '14px',
      letterSpacing: '0.4px',
    },
    overline: {
      fontWeight: 500,
      fontSize: '12px',
      letterSpacing: '2px',
      textTransform: 'uppercase',
    },
  },
});
console.log(theme);
export default theme;
