import { fetchLib, getJson, getStatus } from 'utils';
import { SERVICE_URL } from '../constants';

export const getJobById = async (Authorization, id) => {
  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization,
    },
  };

  const response = await fetchLib(`${SERVICE_URL}api/job/${id}`, options);
  const status = getStatus(response);
  const data = await getJson(response);

  return {
    status,
    data,
  };
};
