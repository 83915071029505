import {
  SET_CANDIDATE_LISTING,
  SET_SELECTED_CANDIDATE,
  TOGGLE_CANDIDATE_AS_SEEN,
  TOGGLE_CANDIDATE_AS_FAVOURITE,
} from 'actions/constants';

const initialState = {
  listing: {},
  selectedCandidate: {},
};

const toggleCandidateAsSeen = (prevState, action) => {
  const {
    payload: { id, seen },
  } = action;
  const newList = prevState.listing.content
    ? Array.from(prevState.listing.content)
    : [];
  newList.forEach((candidate) => {
    if (candidate.id === id) {
      candidate.seen = seen;
    }
  });
  return {
    ...prevState,
    listing: {
      ...prevState.listing,
      content: newList,
    },
    selectedCandidate: {
      ...prevState.selectedCandidate,
      seen: seen,
    },
  };
};

const toggleCandidateAsFavourite = (prevState, action) => {
  const {
    payload: { id, favourite },
  } = action;
  const newList = prevState.listing.content
    ? Array.from(prevState.listing.content)
    : [];
  newList.forEach((candidate) => {
    if (candidate.id === id) {
      candidate.favourite = favourite;
    }
  });
  return {
    ...prevState,
    listing: {
      ...prevState.listing,
      content: newList,
    },
    selectedCandidate: {
      ...prevState.selectedCandidate,
      favourite: favourite,
    },
  };
};

const candidate = (state = initialState, action) => {
  switch (action.type) {
    case SET_CANDIDATE_LISTING:
      return {
        ...state,
        listing: action.listing,
      };
    case SET_SELECTED_CANDIDATE:
      return {
        ...state,
        selectedCandidate: action.selected,
      };
    case TOGGLE_CANDIDATE_AS_SEEN:
      return toggleCandidateAsSeen(state, action);
    case TOGGLE_CANDIDATE_AS_FAVOURITE:
      return toggleCandidateAsFavourite(state, action);
    default:
      return state;
  }
};

export default candidate;
