import { fetchLib, getBlob, getStatus } from 'utils';
import { SERVICE_URL } from '../constants';

export const downloadAllResume = async (Authorization, urls) => {
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization,
    },
    body: JSON.stringify(urls),
  };

  const response = await fetchLib(`${SERVICE_URL}api/drive`, options);
  const status = getStatus(response);
  const data = await getBlob(response);

  return {
    status,
    data,
  };
};
