import { fetchLib, getJson, getStatus, getParamsBuilder } from 'utils';
import { SERVICE_URL } from '../constants';

export const getJobs = async (Authorization, uid, search, filter) => {
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization,
    },
    body: JSON.stringify(filter),
  };

  if (!search) {
    return {
      status: 200,
      data: [],
    };
  }

  const params = getParamsBuilder(search);

  const response = await fetchLib(
    `${SERVICE_URL}api/job/${uid}${params}`,
    options
  );
  const status = getStatus(response);
  const data = await getJson(response);

  return {
    status,
    data,
  };
};
