import { fetchLib, getStatus } from 'utils';
import { SERVICE_URL } from '../constants';

export const editJob = async (Authorization, details) => {
  const options = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization,
    },
    body: JSON.stringify(details),
  };

  const response = await fetchLib(
    `${SERVICE_URL}api/job/${details.jobId}`,
    options
  );
  const status = getStatus(response);

  return {
    status,
  };
};
