import { lazy } from 'react';

const LoginContainer = lazy(() =>
  import(/* webpackPrefetch: true */ 'components/login/loginContainer')
);
const SignupContainer = lazy(() =>
  import(/* webpackPrefetch: true */ 'components/Signup/SignupContainer')
);
const VerifyEmailContainer = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'components/verifyEmail/verifyEmailContainer'
  )
);

const OnboardingContainer = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'components/onboarding/onboardingContainer'
  )
);

const ListingContainer = lazy(() =>
  import(/* webpackPrefetch: true */ 'components/job/listing/listingContainer')
);

const CreateJobContainer = lazy(() =>
  import(/* webpackPrefetch: true */ 'components/job/create/createContainer')
);

const EditJobContainer = lazy(() =>
  import(/* webpackPrefetch: true */ 'components/candidate/edit/editContainer')
);

const ViewJobContainer = lazy(() =>
  import(/* webpackPrefetch: true */ 'components/candidate/view/viewContainer')
);

const CandidateListingContainer = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'components/candidate/listing/listingContainer'
  )
);

const ListContainer = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'components/integrations/list/listContainer'
  )
);

const ProductInfoContainer = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'components/integrations/productInfo/productInfoContainer'
  )
);

const AuthenticateProduct = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'components/integrations/productInfo/authenticateProduct'
  )
);

const PrivacyPolicy = lazy(() =>
  import(/* webpackPrefetch: true */ 'components/privacyPolicy/privacyPolicy')
);

const TOS = lazy(() =>
  import(/* webpackPrefetch: true */ 'components/tos/tos')
);

const ResetPassword = lazy(() =>
  import(/* webpackPrefetch: true */ 'components/resetPassword/resetPassword')
);

// const Talent = lazy(() =>
//   import(/* webpackPrefetch: true */ 'components/talent/talent')
// );

const TalentAiMatching = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'components/talentAiMatching/talentAiMatching'
  )
);

const IdealTalentMatching = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'components/idealTalentMatching/idealTalentMatching'
  )
);

const SkillsRecommendation = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'components/skillsRecommendation/skillsRecommendation'
  )
);

const ApplicantsDashboard = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'components/applicantsDashboard/applicantsDashboard'
  )
);

const ResumeParser = lazy(() =>
  import(/* webpackPrefetch: true */ 'components/resumeParser/resumeParser')
);

const AnalyseExpense = lazy(() =>
  import(/* webpackPrefetch: true */ 'components/analyseExpense/analyseExpense')
);

const AnalyseIdCard = lazy(() =>
  import(/* webpackPrefetch: true */ 'components/analyseIdCard/analyseIdCard')
);

const MatchingDetails = lazy(() =>
  import(
    /* webpackPrefetch: true */ 'components/matchingDetails/matchingDetails'
  )
);

export const ROUTES = [
  {
    path: '/',
    component: LoginContainer,
    title: 'Senseloaf - Login',
    auth: false,
    exact: true,
  },
  {
    path: '/login',
    component: LoginContainer,
    title: 'Senseloaf - Login',
    auth: false,
    exact: true,
  },
  {
    path: '/signup',
    component: SignupContainer,
    title: 'Senseloaf - Signup',
    auth: false,
    exact: true,
  },
  {
    path: '/verify-email',
    component: VerifyEmailContainer,
    title: 'Senseloaf - Verify Email',
    auth: false,
    exact: true,
  },
  {
    path: '/onboarding/:step',
    component: OnboardingContainer,
    title: 'Senseloaf - Onboarding',
    auth: true,
    exact: false,
  },
  {
    path: '/onboarding',
    component: OnboardingContainer,
    title: 'Senseloaf - Onboarding',
    auth: true,
    exact: false,
  },
  {
    path: '/job-list',
    component: ListingContainer,
    title: 'Senseloaf - Job list',
    auth: true,
    exact: true,
  },
  {
    path: '/screened-pipe',
    component: ListingContainer,
    title: 'Senseloaf - Screened Pipe',
    auth: true,
    exact: true,
  },
  {
    path: '/screened-pipe/:id',
    component: CandidateListingContainer,
    title: 'Senseloaf - Screened Pipe',
    auth: true,
    exact: true,
  },
  {
    path: '/screened-pipe/:id/:type',
    component: CandidateListingContainer,
    title: 'Senseloaf - Screened Pipe',
    auth: true,
    exact: true,
  },
  {
    path: '/pool-recommendation',
    component: ListingContainer,
    title: 'Senseloaf - Pool Recommendation',
    auth: true,
    exact: true,
  },
  {
    path: '/pool-recommendation/:id',
    component: CandidateListingContainer,
    title: 'Senseloaf - Pool Recommendation',
    auth: true,
    exact: true,
  },
  {
    path: '/pool-recommendation/:id/:type',
    component: CandidateListingContainer,
    title: 'Senseloaf - Pool Recommendation',
    auth: true,
    exact: true,
  },
  {
    path: '/create-job',
    component: CreateJobContainer,
    title: 'Senseloaf - Create Job',
    auth: true,
    exact: true,
  },
  {
    path: '/job/:id/edit',
    component: EditJobContainer,
    title: 'Senseloaf - Edit Job',
    auth: true,
    exact: true,
  },
  {
    path: '/job/:id/view',
    component: ViewJobContainer,
    title: 'Senseloaf - Job',
    auth: true,
  },
  {
    path: '/job/:id',
    component: ViewJobContainer,
    title: 'Senseloaf - Job',
    auth: true,
    exact: true,
  },
  {
    path: '/candidates/:id',
    component: CandidateListingContainer,
    title: 'Senseloaf - Candidates',
    auth: true,
    exact: true,
  },
  {
    path: '/candidates/:id/:type',
    component: CandidateListingContainer,
    title: 'Senseloaf - Candidates',
    auth: true,
    exact: true,
  },
  // {
  //   path: '/talent/:id',
  //   component: Talent,
  //   title: 'Senseloaf - Talent',
  //   auth: true,
  //   exact: true,
  // },
  // {
  //   path: '/talent/:id/:type',
  //   component: Talent,
  //   title: 'Senseloaf - Talent',
  //   auth: true,
  //   exact: true,
  // },
  {
    path: '/talent-ai-matching',
    component: TalentAiMatching,
    title: 'Senseloaf - Semantic Matching',
    auth: true,
    exact: true,
  },
  {
    path: '/ideal-talent-matching',
    component: IdealTalentMatching,
    title: 'Senseloaf - Ideal Talent Matching',
    auth: true,
    exact: true,
  },
  {
    path: '/integrations',
    component: ListContainer,
    title: 'Senseloaf - Daily tools',
    auth: true,
    exact: true,
  },
  {
    path: '/integrations/:productName',
    component: ProductInfoContainer,
    title: 'Senseloaf - Daily tools',
    auth: true,
    exact: true,
  },
  {
    path: '/integrations/product/:productName',
    component: AuthenticateProduct,
    title: 'Senseloaf - Authenticating',
    auth: true,
    exact: true,
  },
  {
    path: '/privacy-policy',
    component: PrivacyPolicy,
    title: 'Senseloaf - Privacy Policy',
    auth: false,
    exact: true,
  },
  {
    path: '/terms-of-service',
    component: TOS,
    title: 'Senseloaf - Terms of Service',
    auth: false,
    exact: true,
  },
  {
    path: '/reset-password',
    component: ResetPassword,
    title: 'Senseloaf - Reset Password',
    auth: false,
    exact: true,
  },
  {
    path: '/skills-recommendation',
    component: SkillsRecommendation,
    title: 'Senseloaf - Skills Recommendation',
    auth: false,
    exact: true,
  },
  {
    path: '/applicants-dashboard',
    component: ApplicantsDashboard,
    title: 'Senseloaf - Applicants Dashboard',
    auth: true,
    exact: true,
  },
  {
    path: '/applicants-dashboard/:id',
    component: ApplicantsDashboard,
    title: 'Senseloaf - Applicants Dashboard',
    auth: true,
    exact: true,
  },
  {
    path: '/resume-parser',
    component: ResumeParser,
    title: 'Senseloaf - Resume Parser',
    auth: true,
    exact: true,
  },
  {
    path: '/analyse-expense',
    component: AnalyseExpense,
    title: 'Senseloaf - Analyse Expense',
    auth: true,
    exact: true,
  },
  {
    path: '/analyse-ids',
    component: AnalyseIdCard,
    title: 'Senseloaf - Analyse IDs',
    auth: true,
    exact: true,
  },
  {
    path: '/matching-details/:jobTalentId',
    component: MatchingDetails,
    title: 'Senseloaf - Matching Details',
    auth: true,
    exact: true,
  },
];
