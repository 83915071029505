import { fetchLib, getJson, getStatus } from 'utils';
import { SERVICE_URL } from '../constants';

export const searchCandidates = async (
  Authorization,
  searchString,
  page,
  size,
  jobId,
  filters
) => {
  const { searchText } = searchString;
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization,
    },
    body: JSON.stringify(filters),
  };

  if (!searchText) {
    return {
      status: 200,
      data: [],
    };
  }

  const params = `search?searchString=${searchText}&page=${page}&size=${size}&jobId=${jobId}&filter=true`;
  const response = await fetchLib(
    `${SERVICE_URL}api/talent/${params}`,
    options
  );
  const status = getStatus(response);
  const data = await getJson(response);
  return {
    status,
    data,
  };
};
