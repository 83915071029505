import { fetchLib, getJson, getStatus } from 'utils';
import { SERVICE_URL } from '../constants';

export const getJobStatsById = async (Authorization, jobId) => {
  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization,
    },
  };

  const response = await fetchLib(
    `${SERVICE_URL}api/job?jobId=${jobId}`,
    options
  );
  const status = getStatus(response);
  const data = await getJson(response);

  return {
    status,
    data,
  };
};
