import { fetchLib, getStatus } from 'utils';
import { SERVICE_URL } from '../constants';

export const deleteJobs = async (Authorization, jobIds) => {
  const options = {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Authorization,
    },
    body: JSON.stringify(jobIds),
  };

  const response = await fetchLib(`${SERVICE_URL}api/job`, options);
  const status = getStatus(response);

  return {
    status,
  };
};
