import { SET_USER_INTEGRATIONS } from 'actions/constants';
import {
  getAllUserIntegrationsService,
  disableUserIntegrationService,
  getOAuthUrlService,
  authenticateProductService,
  sendEmailService,
} from 'services';
import { dispatch, getAuthorization, getStore } from './controller';
import { setFlag } from './flags';

export const getAllUserIntegrations = async () => {
  const authorization = getAuthorization();

  const { status, data } = await getAllUserIntegrationsService(authorization);

  dispatch({
    type: SET_USER_INTEGRATIONS,
    integrations: data.response,
  });

  if (status !== 200) {
    console.error('Error', status);
    return false;
  }

  return data;
};

export const disableUserIntegration = async (userIntegrationId, isActive) => {
  const authorization = getAuthorization();

  const { status } = await disableUserIntegrationService(
    authorization,
    isActive,
    userIntegrationId
  );

  if (status !== 200) {
    console.error('Error', status);
    return false;
  }

  await getAllUserIntegrations();

  setFlag('integrationDisabled', true);

  return status;
};

export const getOAuthAndRedirectUser = async (product) => {
  const authorization = getAuthorization();
  const { data } = await getOAuthUrlService(authorization, product);

  window.location = data.response.url;
};

export const authenticateProduct = async (product, payload, history) => {
  const authorization = getAuthorization();
  const { status } = await authenticateProductService(
    authorization,
    product,
    payload
  );

  if (status !== 200) {
    const store = getStore();
    history.push({
      pathname: `/integrations/${product}`,
      emailId: store.user.details.emailId,
    });

    return false;
  }

  await getAllUserIntegrations();
  history.push('/integrations');
};

export const sendEmail = async (body, emailIds, subject) => {
  const authorization = getAuthorization();
  const state = getStore();
  const { integrations = [] } = state.user;

  const product = integrations.find(
    ({ isActive, integrationDto }) =>
      isActive && integrationDto.integrationType === 'Email'
  );

  await sendEmailService(
    authorization,
    product.userIntegrationId,
    product.integrationDto.product,
    {
      body,
      emailIds,
      subject,
    }
  );

  setFlag('sendEmail', true);
};
