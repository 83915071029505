import { fetchLib, getJson, getStatus } from 'utils';
import { SERVICE_URL } from '../constants';

export const authenticateProduct = async (
  Authorization,
  product,
  payload = {}
) => {
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization,
    },
    body: JSON.stringify(payload),
  };

  const response = await fetchLib(
    `${SERVICE_URL}api/${product}/authenticate`,
    options
  );
  const status = getStatus(response);
  const data = await getJson(response);

  return {
    status,
    data,
  };
};
