import React, { Component } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { getAuthorization } from 'controllers/controller';

class AuthCheck extends Component {
  setTitle = (title) => {
    window.document.title = title;
  };

  renderComponent = ({ auth, title, Component, props }) => {
    this.setTitle(title);

    if (auth && !getAuthorization()) {
      const { pathname, search } = props.location;

      if (pathname || search) {
        localStorage.setItem('pathname', pathname + search);
      }

      return <Redirect to="/login" />;
    }

    return <Component {...props} />;
  };

  render() {
    const { path, component, auth, title } = this.props;
    return (
      <Route
        exact
        path={path}
        render={(props) =>
          this.renderComponent({
            Component: component,
            auth,
            title,
            props,
          })
        }
      />
    );
  }
}

export default AuthCheck;
