import {
  SET_JOB_LISTING,
  RESET_SELECTED_JOB,
  SET_SELECTED_JOB,
  UPDATE_SELECTED_JOB,
  SET_JOB_STATUS_COUNTS,
  SET_TALENT_STATUS_COUNT,
  ADD_COLLEGE,
  REMOVE_COLLEGE,
  ADD_DOMAIN,
  REMOVE_DOMAIN,
} from 'actions/constants';

const initialState = {
  listing: {},
  selectedJob: {
    company: '',
    currency: '',
    educationRequired: [],
    employmentType: 'Fulltime',
    jobDescription: '',
    jobLocation: [],
    jobRole: '',
    maxExperience: 4,
    maxSalary: 0,
    minExperience: 2,
    minSalary: 0,
    organizationId: '',
    skills: [],
    positionCount: 0,
    currencyValue: '',
    userId: '',
    lookForSchools: [],
    domains: [],
    perks: [],
  },
  jobStatusCounts: {
    candidates: 0,
    shortlisted: 0,
    interview: 0,
    hired: 0,
    offered: 0,
    rejected: 0,
  },
};

const addDomain = (prevState, { payload }) => {
  const { domain } = payload;
  const domains = Array.from(prevState.selectedJob.domains);
  domains.push(domain);

  return {
    ...prevState,
    selectedJob: {
      ...prevState.selectedJob,
      domains,
    },
  };
};

const removeDomain = (prevState, { payload }) => {
  const { domain } = payload;
  const domains = Array.from(prevState.selectedJob.domains);
  return {
    ...prevState,
    selectedJob: {
      ...prevState.selectedJob,
      domains: domains.filter((d) => d !== domain),
    },
  };
};

const addSchool = (prevState, { payload }) => {
  const { college } = payload;
  const lookForSchools = Array.from(prevState.selectedJob.lookForSchools);
  lookForSchools.push(college);

  return {
    ...prevState,
    selectedJob: {
      ...prevState.selectedJob,
      lookForSchools,
    },
  };
};

const removeSchool = (prevState, { payload }) => {
  const { college } = payload;
  const lookForSchools = Array.from(prevState.selectedJob.lookForSchools);
  return {
    ...prevState,
    selectedJob: {
      ...prevState.selectedJob,
      lookForSchools: lookForSchools.filter((d) => d !== college),
    },
  };
};

const initializeSelectedJob = (prevState, { payload }) => {
  const { organizationInfo } = payload;
  const {
    aboutCompany,
    foundedYear,
    location,
    companySize,
    perks,
    domains,
    organizationName,
    organizationId,
  } = organizationInfo;

  return {
    ...prevState,
    selectedJob: {
      ...initialState.selectedJob,
      aboutCompany,
      foundedYear,
      location,
      companySize,
      perks,
      domains,
      organizationId,
      company: organizationName,
    },
  };
};

const job = (state = initialState, action) => {
  switch (action.type) {
    case SET_JOB_LISTING:
      return {
        ...state,
        listing: action.listing,
        jobStats: {},
      };
    case RESET_SELECTED_JOB:
      return initializeSelectedJob(state, action);
    case SET_SELECTED_JOB:
      return {
        ...state,
        selectedJob: action.selectedJob,
      };
    case UPDATE_SELECTED_JOB:
      return {
        ...state,
        selectedJob: {
          ...state.selectedJob,
          ...action.jobDetails,
        },
      };
    case SET_JOB_STATUS_COUNTS:
      return {
        ...state,
        jobStatusCounts: {
          ...state.selectedJob.jobStatusCounts,
          ...action.jobStatusCounts,
        },
      };
    case SET_TALENT_STATUS_COUNT:
      return {
        ...state,
        jobStatusCounts: {
          ...state.jobStatusCounts,
          [action.payload.statusName]: action.payload.count,
        },
      };
    case ADD_COLLEGE:
      return addSchool(state, action);
    case REMOVE_COLLEGE:
      return removeSchool(state, action);
    case ADD_DOMAIN:
      return addDomain(state, action);
    case REMOVE_DOMAIN:
      return removeDomain(state, action);
    case 'UPDATE_SELECTED_JOB_NEW':
      return {
        ...state,
        selectedJob: {
          ...state.selectedJob,
          ...action.payload,
        },
      };
    default:
      return state;
  }
};

export default job;
