import { fetchLib, getJson, getStatus } from 'utils';
import { SERVICE_URL } from '../constants';

export const getOAuthUrl = async (Authorization, product) => {
  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization,
    },
  };

  const response = await fetchLib(
    `${SERVICE_URL}api/${product}/auth-url`,
    options
  );
  const status = getStatus(response);
  const data = await getJson(response);

  return {
    status,
    data,
  };
};
