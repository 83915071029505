import React, { Component, Suspense } from 'react';
import { Switch, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { ROUTES } from './routeList';
import { uuid } from 'utils';
import AuthCheck from './authcheck';
import { Loader } from 'common/loader/loader';
import { getUserDetails } from 'controllers/userController';

class Routes extends Component {
  componentDidMount() {
    const { initialized } = this.props;

    if (!initialized) {
      getUserDetails();
    }
  }

  renderRoutes = () => {
    return ROUTES.map(({ path, component, auth, title, exact }) => (
      <AuthCheck
        auth={auth}
        title={title}
        key={uuid()}
        exact={exact}
        path={path}
        component={component}
      />
    ));
  };

  render() {
    const { initialized } = this.props;

    if (!initialized) {
      return <Loader />;
    }

    return (
      <div>
        <Suspense fallback={<Loader />}>
          <Switch>
            <this.renderRoutes />
            <Route component={Loader} />
          </Switch>
        </Suspense>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    initialized: state.user.initialized,
  };
};

export default connect(mapStateToProps)(Routes);
