import { fetchLib, getStatus } from 'utils';
import { SERVICE_URL } from '../constants';

export const shareCandidate = async (Authorization, details) => {
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization,
    },
    body: JSON.stringify(details),
  };

  const response = await fetchLib(`${SERVICE_URL}api/send-mail`, options);
  const status = getStatus(response);

  return {
    status,
  };
};
