import { fetchLib, getJson, getStatus } from 'utils';
import { SERVICE_URL } from '../constants';

export const disableUserIntegration = async (
  Authorization,
  isActive,
  userIntegrationId
) => {
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization,
    },
  };

  const response = await fetchLib(
    `${SERVICE_URL}integration/user?isActive=${isActive}&userIntegrationId=${userIntegrationId}`,
    options
  );
  const status = getStatus(response);
  const data = await getJson(response);

  return {
    status,
    data,
  };
};
