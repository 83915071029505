import { fetchLib, getJson, getStatus } from 'utils';
import { SERVICE_URL } from '../constants';

export const getUserDetails = async (Authorization, emailId) => {
  const options = {
    method: 'POST',
    headers: {
      Authorization,
    },
  };

  const response = await fetchLib(`${SERVICE_URL}api/user`, options);
  const status = getStatus(response);
  const data = getJson(response);

  return {
    status,
    data,
  };
};
