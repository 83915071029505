export const fetchLib = (url, options) => {
  return fetch(url, options)
    .then((response) => response)
    .catch((err) => err);
};

export const getHeader = (response, headerKey) => {
  return response.headers.get(headerKey);
};

export const getJson = (response) => {
  if (response && response.json) {
    return response.json();
  }

  return false;
};

export const getStatus = (response) => {
  return response.status;
};

export const getBlob = (response) => {
  if (response && response.blob) {
    return response.blob();
  }
};
