import { fetchLib, getBlob, getStatus } from 'utils';

export const downloadResume = async (url) => {
  const options = {
    method: 'GET',
  };

  const response = await fetchLib(url, options);
  const status = getStatus(response);
  const data = await getBlob(response);

  return {
    status,
    data,
  };
};
