import { fetchLib, getJson, getStatus } from 'utils';
import { SERVICE_URL } from '../constants';

export const getResumeURL = async (Authorization, filename) => {
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization,
    },
    body: JSON.stringify({ filename }),
  };

  const response = await fetchLib(`${SERVICE_URL}api/drive/url`, options);
  const status = getStatus(response);
  const data = await getJson(response);

  return {
    status,
    data,
  };
};
