import { dispatch } from './controller';
import { SET_FLAG, RESET_FLAGS } from 'actions/constants';

export const setFlag = (flag, showFlag) => {
  dispatch({
    type: SET_FLAG,
    flag,
    showFlag,
  });
};

export const resetFlags = () => {
  dispatch({
    type: RESET_FLAGS,
  });
};
