import store from '../store';

export const getStore = () => store.getState();
export const dispatch = (data) => store.dispatch(data);
export const getAuthorization = () => {
  const auth = localStorage.getItem('authorization');
  return auth === 'null' ? false : auth;
};
export const setAuthorization = (auth = null) =>
  localStorage.setItem('authorization', auth);
