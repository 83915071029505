import {
  SET_USER_DETAILS,
  UPDATE_ORGANIZATION_INFO,
  SET_APP_INITIALIZED,
  SET_USER_INTEGRATIONS,
  SET_NOTIFICATIONS,
  SET_MENU_VISIBILITY,
} from 'actions/constants';
import { userDetailsMapper } from 'mappers/userMapper';

const initialState = {
  initialized: false,
  details: {
    userId: '',
    userName: '',
    emailId: '',
    phone: '',
    organizationInfo: {
      organizationId: '',
      organizationName: '',
      location: '',
      foundedYear: '',
      companySize: '',
      aboutCompany: '',
      websiteLink: '',
      linkedinLink: '',
      perks: [],
      domains: [],
      technologies: [],
    },
    timeZone: 'IST',
    onBoarded: false,
  },
  integrations: [],
  notifications: {
    page: 0,
    size: 5,
    count: 0,
    hasMore: false,
    list: [],
    showNotifications: false,
  },
  isMenuOpen: false,
};

const user = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER_DETAILS:
      return {
        ...state,
        details: {
          ...action.userDetails,
          organizationInfo: userDetailsMapper(
            action.userDetails.organizationInfo
          ),
        },
      };
    case SET_APP_INITIALIZED:
      return {
        ...state,
        initialized: true,
      };
    case UPDATE_ORGANIZATION_INFO:
      return {
        ...state,
        details: {
          ...state.details,
          organizationInfo: {
            ...state.details.organizationInfo,
            ...action.orgData,
          },
        },
      };
    case SET_USER_INTEGRATIONS:
      return {
        ...state,
        integrations: action.integrations,
      };
    case SET_NOTIFICATIONS:
      return {
        ...state,
        notifications: {
          ...state.notifications,
          ...action.notifications,
        },
      };
    case SET_MENU_VISIBILITY:
      return {
        ...state,
        isMenuOpen: action.isMenuOpen,
      };
    default:
      return state;
  }
};

export default user;
