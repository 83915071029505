import { fetchLib, getHeader, getStatus } from 'utils';
import { SERVICE_URL } from '../constants';

export const login = async (emailId, password) => {
  const options = {
    method: 'POST',
    body: JSON.stringify({
      emailId,
      password,
    }),
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const response = await fetchLib(`${SERVICE_URL}login`, options);
  const status = getStatus(response);
  const authToken = getHeader(response, 'authorization');

  return {
    status,
    authToken,
  };
};
