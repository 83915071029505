import { fetchLib, getBlob, getStatus } from 'utils';
import { SERVICE_URL } from '../constants';

export const downloadCandidates = async (
  Authorization,
  jobId,
  candidateStatus
) => {
  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization,
    },
  };

  const URL = `${SERVICE_URL}api/talent/export?jobId=${jobId}&status=${candidateStatus}`;

  const response = await fetchLib(URL, options);
  const status = getStatus(response);
  const data = await getBlob(response);

  return {
    status,
    data,
  };
};
