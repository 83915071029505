import { getUserDetailsService, updateUserDetailsService } from 'services';
import {
  getAuthorization,
  dispatch,
  getStore,
  setAuthorization,
} from './controller';
import { userDetailsDeMapper } from 'mappers/userMapper';
import {
  UPDATE_ORGANIZATION_INFO,
  SET_USER_DETAILS,
  SET_APP_INITIALIZED,
} from 'actions/constants';
import { setFlag } from './flags';
import { getAllUserIntegrations } from './integrations';

export const getUserDetails = async () => {
  const authorization = getAuthorization();

  if (!authorization) {
    dispatch({
      type: SET_APP_INITIALIZED,
    });
    return false;
  }

  const { status, data } = await getUserDetailsService(authorization);

  if (status !== 200) {
    console.error('Error', status);
    setAuthorization(null);
    dispatch({
      type: SET_APP_INITIALIZED,
    });
    return false;
  }

  await getAllUserIntegrations();

  const mappedData = await data;

  dispatch({
    type: SET_USER_DETAILS,
    userDetails: mappedData.response,
  });

  dispatch({
    type: SET_APP_INITIALIZED,
  });

  return mappedData;
};

export const updateUserDetails = async () => {
  const authorization = getAuthorization();
  const { details } = getStore().user;
  const orgInfo = userDetailsDeMapper(details.organizationInfo);
  const { status, data } = await updateUserDetailsService(
    authorization,
    details.userId,
    orgInfo
  );

  if (status !== 201) {
    console.error('Error', status);
    return false;
  }

  const mappedData = await data;

  setFlag('onBoarding', true);
  return mappedData;
};

export const updateOnBoardingDetails = (orgData) => {
  dispatch({
    type: UPDATE_ORGANIZATION_INFO,
    orgData,
  });
};
