import { fetchLib, getJson, getStatus } from 'utils';
import { SERVICE_URL } from '../constants';

export const skillsRecommendation = async (Authorization, skillsArray) => {
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization,
    },
    body: JSON.stringify({ skills: skillsArray }),
  };

  if (!skillsArray.length) {
    return {
      status: 200,
      data: [],
    };
  }

  const response = await fetchLib(
    `${SERVICE_URL}api/recommend/skill/v2`,
    options
  );
  const status = getStatus(response);
  const data = getJson(response);

  return {
    status,
    data,
  };
};
