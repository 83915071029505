import { fetchLib, getJson, getStatus } from 'utils';
import { SERVICE_URL } from '../constants';

export const updateUserDetails = async (Authorization, userId, payload) => {
  const options = {
    method: 'PUT',
    body: JSON.stringify(payload),
    headers: {
      Authorization,
      'Content-Type': 'application/json',
    },
  };

  const response = await fetchLib(`${SERVICE_URL}api/org/${userId}`, options);
  const status = getStatus(response);
  const data = getJson(response);

  return {
    status,
    data,
  };
};
