export const getParamsBuilder = (params) => {
  const paramsArray = Object.keys(params);

  if (!paramsArray.length) {
    return '';
  }

  return paramsArray.reduce((acc, key, index) => {
    return `${acc}${key}=${params[key]}${
      paramsArray.length > index + 1 ? '&' : ''
    }`;
  }, '?');
};
