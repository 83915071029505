import { fetchLib, getJson, getStatus } from 'utils';
import { SERVICE_URL } from '../constants';

export const skill = async (Authorization, search) => {
  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization,
    },
  };

  if (!search) {
    return {
      status: 200,
      data: [],
    };
  }

  const response = await fetchLib(
    `${SERVICE_URL}api/auto/skill/${search}`,
    options
  );
  const status = getStatus(response);
  const data = getJson(response);

  return {
    status,
    data,
  };
};
