import { combineReducers } from 'redux';
import user from './user';
import job from './job';
import candidate from './candidate';
import flags from './flags';
import talent from './talent';

export default combineReducers({
  user,
  job,
  candidate,
  flags,
  talent,
});
