import { SET_FLAG, RESET_FLAGS } from 'actions/constants';

const initialState = {
  login: false,
  error: false,
  createJob: false,
  editJob: false,
  deleteJob: false,
  deleteJobs: false,
  shortlisted: false,
  advanceCandidate: false,
  onBoarding: false,
  bulkDownload: false,
  notifyCandidate: false,
  TalentAiFileTypeError: false,
  TalentAiMultipleFilesError: false,
};

const flags = (state = initialState, action) => {
  switch (action.type) {
    case SET_FLAG:
      return {
        ...state,
        [action.flag]: action.showFlag,
      };
    case RESET_FLAGS:
      return initialState;
    default:
      return state;
  }
};

export default flags;
