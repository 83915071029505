import { createStore, compose } from 'redux';

import Reducers from './reducers/index';

let store;

if (process.env.NODE_ENV !== 'production') {
  const middleware = [
    ...(window.__REDUX_DEVTOOLS_EXTENSION__
      ? [window.__REDUX_DEVTOOLS_EXTENSION__()]
      : []),
  ];
  store = createStore(Reducers, compose(...middleware));
} else {
  store = createStore(Reducers);
}

export default store;
