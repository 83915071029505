export const userDetailsMapper = (userDetails) => {
  const {
    location,
    foundedYear,
    companySize,
    aboutCompany,
    websiteLink,
    linkedinLink,
    perks,
    domains,
    technologies,
  } = userDetails;

  return {
    ...userDetails,
    location: location || '',
    foundedYear: foundedYear || '',
    companySize: companySize || '',
    aboutCompany: aboutCompany || '',
    websiteLink: websiteLink || '',
    linkedinLink: linkedinLink || '',
    perks: perks || '',
    domains: domains || '',
    technologies: technologies || '',
  };
};

export const userDetailsDeMapper = (userDetails) => {
  const {
    location,
    foundedYear,
    companySize,
    aboutCompany,
    websiteLink,
    linkedinLink,
    perks = [],
    domains = [],
    technologies = [],
  } = userDetails;

  const domainArray = domains.map((domain) => domain.trim());
  const technologiesArray = technologies.map((technology) => technology.trim());

  return {
    ...userDetails,
    location: location || '',
    foundedYear: foundedYear || '',
    companySize: companySize || '',
    aboutCompany: aboutCompany || '',
    websiteLink: websiteLink || '',
    linkedinLink: linkedinLink || '',
    perks: perks || [],
    domains: domainArray,
    technologies: technologiesArray,
  };
};
