import {
  SET_PIPELINE,
  SET_SELECTED_PIPELINE,
  SET_SELECTED_TALENT_TAB,
  SET_TALENT_LIST,
  RESET_TALENT_LIST,
  SET_SELECTED_TALENT,
  SET_TALENT_PAGINATION,
  SET_TALENT_LIST_LOADED,
  SET_FILTER_OPTIONS,
  SET_FILTER_ACCORDIAN,
  SET_OVERVIEW_LEFT_MENU_SELECTED,
} from 'actions/constants';

const initialState = {
  talentList: [],
  pipeline: {},
  selectedPipeline: 'Applied',
  selectedTab: 'Applicants',
  selectedTalent: '',
  currentPage: 0,
  pageSize: 10,
  totalTalent: 10,
  sortby: 'overallScore',
  checkedTalent: [],
  allChecked: false,
  selectedOverviewSection: 'experience',
  filters: {
    filterOptions: {},
    accordian: {
      weightage: true,
      location: true,
      distance: true,
      tags: true,
      domains: true,
      company: true,
      currentCompany: true,
      jobTitles: true,
      skills: true,
      experiance: true,
      match: true,
      schools: true,
      graduatedYear: true,
    },
  },
  isTalentListLoaded: false,
};

const talent = (state = initialState, action) => {
  switch (action.type) {
    case SET_PIPELINE:
      return {
        ...state,
        pipeline: { ...state.pipeline, ...action.pipelineStatus },
      };
    case SET_SELECTED_PIPELINE:
      return {
        ...state,
        selectedPipeline: action.selectedPipeline,
      };
    case SET_SELECTED_TALENT_TAB:
      return {
        ...state,
        selectedTab: action.selectedTab,
      };
    case SET_TALENT_LIST:
      return {
        ...state,
        talentList: [...state.talentList, ...action.talentList],
      };
    case RESET_TALENT_LIST:
      return {
        ...state,
        talentList: [],
      };
    case SET_SELECTED_TALENT:
      return {
        ...state,
        selectedTalent: action.selectedTalent,
      };
    case SET_TALENT_LIST_LOADED:
      return {
        ...state,
        isTalentListLoaded: action.isTalentListLoaded,
      };
    case SET_TALENT_PAGINATION:
      return {
        ...state,
        currentPage: action.currentPage,
        pageSize: action.pageSize,
        totalTalent: action.totalTalent,
        totalPages: action.totalPages,
      };
    case SET_FILTER_OPTIONS:
      return {
        ...state,
        filters: {
          ...state.filters,
          filterOptions: action.filterOptions,
        },
      };
    case SET_FILTER_ACCORDIAN:
      return {
        ...state,
        filters: {
          ...state.filters,
          accordian: { ...state.filters.accordian, ...action.accordian },
        },
      };
    case SET_OVERVIEW_LEFT_MENU_SELECTED:
      return {
        ...state,
        selectedOverviewSection: action.selectedOverviewSection,
      };
    default:
      return state;
  }
};

export default talent;
